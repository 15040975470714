import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Label, Input, Spinner, Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getDetail, updateDetail } from "../../store/thunks";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumb from 'Components/Common/BreadCrumb'
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import "react-toastify/dist/ReactToastify.css";
import ModalConfirm from "Components/Common/ModalConfirm";
// import avatar1 from '../../assets/images/users/avatar-9.jpg'


function Setting() {
    const { t, i18n } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOpenConfirmUpdate, setIsConfirmUpdate] = useState<boolean>(false);

    const dispatch: any = useDispatch()

    const handleShowConfirmUpdateCampaign = () => {
        setIsConfirmUpdate(true)
    };

    const selectSettingState = (state: any) => state.Setting;

    const SettingProperties = createSelector(
        selectSettingState,
        (state) => ({
            detail: state.detail,
            isSettingSuccess: state.isSettingSuccess,
            isSettingLoading: state.isSettingLoading,
            error: state.error,
        })
    );

    // Inside your component
    const { detail, isSettingLoading } = useSelector(SettingProperties);

    // Inside your component
    useEffect(() => {
        dispatch(getDetail());
    }, []);


    useEffect(() => {
        setDataFromList()
    }, [detail])




    const validationSchema = Yup.object({
        // profile_url: Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, `${t('Enter correct Profile URL')}`).required(`${t('Profile URL is required')}`),
        contact_name: Yup.string().required(`${t('Contact Name is required')}`),
        contact_phone: Yup.string().required(`${t('Contact Phone is required')}`),
        contact_email: Yup.string()
            .email('Please enter a valid contact email address')
            .required(`${t('Contact Email is required')}`),
    })

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true)
            const data = {
                contact_name: values?.contact_name ?? '',
                // profile_url: values?.profile_url ?? '',
                contact_phone: values?.contact_phone ?? '',
                contact_email: values?.contact_email ?? '',
            }
            const response: any = await dispatch(updateDetail(data))

            if (response?.payload?.code === 200) {
                setIsLoading((_prev) => false)
                setIsConfirmUpdate((_prev) => false);

                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL)
            } else {
                setIsLoading((_prev) => false)
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR)
            }
        } catch (error: any) {
            setIsLoading((_prev) => false)
            setIsConfirmUpdate((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR)
            return error
        }
    }

    const handleUpdateSetting = () => {
        handleSubmit(formik.values);
    }


    const setDataFromList = () => {
        if (detail?.id) {
            const { contact_name, contact_phone, contact_email, name } = detail;

            const valueUpdate: any = {
                name: name ?? '',
                // profile_url: profile_url ?? '',
                contact_name: contact_name ?? '',
                contact_phone: contact_phone ?? '',
                contact_email: contact_email ?? '',
            }

            formik.setValues({ ...formik.values, ...valueUpdate })
        }

    }


    const handleReset = () => {
        setDataFromList()
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            // profile_url: '',
            contact_name: '',
            contact_phone: '',
            contact_email: '',
        },
        validationSchema,
        onSubmit: handleShowConfirmUpdateCampaign,
    })

    const selectLayoutState = (state: any) => {
        return state.Layout
    }
    const selectLayoutProperties = createSelector(selectLayoutState, (layout: any) => ({
        languageType: layout.languageType,
    }))

    // const { layoutType, layoutModeType, topbarThemeType, leftSidebarType } = useSelector(selectLayoutProperties)
    const { languageType } = useSelector(selectLayoutProperties)

    useEffect(() => {
        document.title = `${t('Setting')} - ${t('Setting')} | NewsHub`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const onCloseConfirmUpdateClick = () => {
        setIsConfirmUpdate((_prev) => false);
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Setting')} pageTitle={t('Setting')} />
                    <form onSubmit={formik.handleSubmit} onReset={handleReset}>

                        <Row className="mt-1 g-5 mb-5">
                            <Col sm={0} md={0} lg={1} xl={3} className="m-0"></Col>

                            <Col sm={12} md={12} lg={10} xl={6}>
                                <div
                                    className="row g-3"
                                    style={{
                                        backgroundColor: "var(--vz-topbar-search-bg)",
                                        padding: "10px 10px 20px",
                                        borderRadius: "5px",
                                    }}
                                >

                                    <Col sm={12} md={12}>
                                        <div>
                                            <label className="form-label">
                                                {t("Name")}
                                            </label>
                                            <Input
                                                type="text"
                                                onChange={(e: any) => formik.setFieldValue("name", e.target.value)}
                                                placeholder={`${t('Enter Name')}...`}
                                                value={formik.values?.name}
                                                disabled
                                            />
                                        </div>
                                    </Col>

                                    <Col sm={12} md={12}>
                                        <div>
                                            <label className="form-label">
                                                {t("Contact Name")}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <Input
                                                type="text"
                                                onChange={(e: any) => formik.setFieldValue("contact_name", e.target.value)}
                                                placeholder={`${t('Enter Contact Name')}...`}
                                                value={formik.values?.contact_name}
                                            />
                                            {formik.touched.contact_name && formik.errors.contact_name ? (
                                                <div className="text-danger mt-2">
                                                    {formik.errors.contact_name}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Col>

                                    {/* <Col sm={12} md={12}>
                                        <div>
                                            <label className="form-label">
                                                {t("Profile URL")}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <Input
                                                type="text"
                                                onChange={(e: any) => formik.setFieldValue("profile_url", e.target.value)}
                                                placeholder={`${t('Enter Profile URL')}...`}
                                                value={formik.values?.profile_url}
                                            />
                                            {formik.touched.profile_url && formik.errors.profile_url ? (
                                                <div className="text-danger mt-2">
                                                    {formik.errors.profile_url}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Col> */}

                                    <Col sm={12} md={12}>
                                        <div>
                                            <label className="form-label">
                                                {t("Contact Phone")}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <Input
                                                type="text"
                                                onChange={(e: any) => formik.setFieldValue("contact_phone", e.target.value)}
                                                placeholder={`${t('Enter Contact Phone')}...`}
                                                value={formik.values?.contact_phone}
                                            />
                                            {formik.touched.contact_phone && formik.errors.contact_phone ? (
                                                <div className="text-danger mt-2">
                                                    {formik.errors.contact_phone}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <Col sm={12} md={12}>
                                        <div>
                                            <label className="form-label">
                                                {t("Contact Email")}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <Input
                                                type="text"
                                                onChange={(e: any) => formik.setFieldValue("contact_email", e.target.value)}
                                                placeholder={`${t('Enter Contact Email')}...`}
                                                value={formik.values?.contact_email}
                                            />
                                            {formik.touched.contact_email && formik.errors.contact_email ? (
                                                <div className="text-danger mt-2">
                                                    {formik.errors.contact_email}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <div className="col-lg-12">
                                        <div className={`hstack gap-2 justify-content-md-start justify-content-lg-end mt-1`}>
                                            <button
                                                className="btn btn-success fs-14"
                                                color="light"
                                                type="submit"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <Spinner size="sm" className="me-2"></Spinner> : (
                                                    <i className="ri-login-circle-line align-bottom me-1"></i>
                                                )}
                                                {t("Button Save")}
                                            </button>

                                            <button
                                                className="btn btn-secondary fs-14"
                                                color="success"
                                                type="reset"
                                                disabled={isLoading}
                                            >
                                                <i className="ri-refresh-line align-bottom me-1"></i>
                                                {t('Button Reset')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
            <ModalConfirm
                textButtonConfirm={t("Button Save")}
                classButtonConfirm='btn-success'
                classIconButtonConfirm={'ri-login-circle-line'}
                header={t('Update Contact')}
                title={t('Are you sure you want to update ?')}
                content={t('After confirming the update action, the data will be updated. Do you want to proceed with the confirmation action ?')}
                isShowIcon={false}
                isOpen={isOpenConfirmUpdate}
                onClose={onCloseConfirmUpdateClick}
                onConfirm={handleUpdateSetting}
                isLoading={isLoading}
            />
            <ToastContainer closeButton={false} limit={1} />
        </React.Fragment>
    )
}

export default Setting
