import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-US'
import ko from 'date-fns/locale/ko'
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import { useRole } from 'Components/Hooks/UserHooks';
import CountUp from 'react-countup';
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";

import TableContainer from '../../Components/Common/TableContainer'
import { listDataNotificationPage } from '../../common/data'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getListNotification } from "../../store/thunks";
import TooltipCustom from '../../Components/Common/TooltipCustom';
import { Link, useNavigate } from "react-router-dom";

const TYPE_SELECT_DEFAULT: string = ''
registerLocale('en', en)
registerLocale('ko', ko)

const typeQuery = {
    id: StringParam,
    schedule: StringParam,
}

const COLORS_STATUS_KEYWORD = ["danger", "success", "primary"];


const NotificationPage = () => {
    const { t, i18n } = useTranslation()

    const formatDate = (strDate: string = '') => {
        return `${String(strDate).split('T')[0]} ${String(String(strDate).split('T')[1]).split('.')[0]}`;
    }

    const handlePreviewDetail = async (item: any | null = null) => {
        try {
            setPreviewDetail((_prev: any) => item);
        } catch (error: any) {
            return error;
        }
    };


    const showDate = (value: any) => {
        const originalDate = value;
        const formattedDate = moment(originalDate).format('YYYY-MM-DD')
        return formattedDate;
    }

    const columns = [
        {
            Header: t('Date'),
            accessor: "created_at",
            filterable: true,
            sortable: false,
            thWidth: 300,
            Cell: (cell: any) => {
                return (
                    <div style={{
                        width: '300px'
                    }}>
                        {showDate(cell?.value)}
                    </div>
                )
            },
        },
        {
            Header: t('Title'),
            accessor: 'title',
            filterable: true,
            sortable: false,
            Cell: (cell: any) => {
                const item: any = cell?.row?.original;
                return (
                    <div onClick={(e) => { e.preventDefault(); handlePreviewDetail(item) }} style={{ cursor: 'pointer' }}
                    >
                        <span>{cell?.value}</span>
                    </div>
                )
            },
        },
    ]


    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
        order_by: withDefault(StringParam, ``),
        title: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, ''),
        end_date: withDefault(StringParam, ''),
    });


    const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date || "", 'Y-MM-DD').toDate() : '', query?.end_date ? moment(query?.end_date || "", 'Y-MM-DD').toDate() : '']);
    const [startDate, endDate] = dateSearch;

    const [previewDetail, setPreviewDetail] = useState<any | null>(null);


    const { userPermissions } = useRole();

    const dispatch: any = useDispatch();

    const selectNotificationState = (state: any) => state.Notification;

    const NotificationProperties = createSelector(
        selectNotificationState,
        (state) => ({
            listNotification: state.listNotification,
            isNotificationSuccess: state.isNotificationSuccess,
            isNotificationLoading: state.isNotificationLoading,
            error: state.error,
        })
    );

    const { listNotification, error, isNotificationLoading } = useSelector(NotificationProperties);


    useEffect(() => {
        dispatch(getListNotification(query));
    }, [dispatch, JSON.stringify(query)]);


    const searchData = () => {
        const queryNew = {
            ...query,
            title: titleSearch || "",
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            sort_by: TYPE_SELECT_DEFAULT,
            page: 1,
            time_request: + new Date(),
        };

        setQuery(queryNew);
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            title: '',
            sort_by: TYPE_SELECT_DEFAULT,
            start_date: undefined,
            end_date: undefined,
            page: 1,
            time_request: + new Date(),
        };
        setQuery(queryNew, "push");
        setTitleSearch((_prev) => "");
    };


    const handleChangePage = (page: any) => {
        setQuery({ page: page + 1 })
    }

    const handleChangeSorting = (sortBy: any) => {
        setQuery((_prev: any) => {
            return { ..._prev, ...sortBy }
        })
    }

    useEffect(() => {
        document.title = `${t('Notification')} - ${t('Notification')} | NewsHub`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    function onClosePreviewDetailClick() {
        setPreviewDetail((_prev: any) => null);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Notification')} pageTitle={t('Notification')} />
                    <Row>
                        {isHavePermissionRole(ROLES_FOR_APP.NOTIFICATION_LIST, userPermissions) && (
                            <Col lg={12}>
                                <Card id="leadsList">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <Col sm={12} className="text-end mb-2 mb-md-0 d-block d-md-none">
                                                <div className="d-flex flex g-4 align-items-end align-items-md-end mb-2 mt-2">
                                                    <Card className="card-animate mb-0 me-0 me-md-4 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                                                        <CardBody>
                                                            <div className="d-flex align-items-center ">
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <p className="text-uppercase fw-medium text-primary text-truncate mb-0 text-start">{t('Total')}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                                                <div>
                                                                    <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                                        <span className="counter-value text-primary">
                                                                            <CountUp
                                                                                start={0}
                                                                                end={listNotification?.pagination?.total || 0}
                                                                                duration={1}
                                                                            />
                                                                        </span></h4>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>

                                            </Col>
                                        </Row>

                                        <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-xl-end mb-2 mt-2">
                                            <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0  d-md-block d-none" style={{ width: '200px' }}>
                                                <CardBody>
                                                    <div className="d-flex align-items-center ">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                                                        <div>
                                                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                                <span className="counter-value text-primary">
                                                                    {isNotificationLoading ? <Spinner size="sm" ></Spinner> : (
                                                                        <CountUp
                                                                            start={0}
                                                                            end={listNotification?.pagination?.total || 0}
                                                                            duration={1}
                                                                        />
                                                                    )}
                                                                </span></h4>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <div className="w-100">
                                                <Row className="g-4 align-items-center mb-2 mb-md-0 mt-0">
                                                    <Col md={12} lg={12} xl={4} className='mt-3'
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder={`${t('Title')}...`}
                                                            value={titleSearch}
                                                            onChange={(e) => setTitleSearch(e.target.value)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    searchData();
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6} lg={6} xl={4} className="mt-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary me-1"
                                                            onClick={searchData}
                                                            disabled={isNotificationLoading}
                                                        >
                                                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                            {t('Button Search')}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary fs-14"
                                                            onClick={resetData}
                                                        >
                                                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                            {t('Button Reset')}
                                                        </button>
                                                    </Col>

                                                </Row>
                                            </div>

                                        </div>

                                    </CardHeader>


                                    <CardBody className="pt-3">
                                        <div>
                                            <TableContainer
                                                className="custom-header-css"
                                                divClass="table-responsive table-card"
                                                tableClass="align-middle"
                                                theadClass="table-light"
                                                columns={columns}
                                                data={listNotification?.list?.length ? listNotification?.list : []}
                                                customPageSize={query.limit}
                                                customPageIndex={query.page - 1}
                                                totalRecords={listNotification?.pagination?.total}
                                                customPageCount={Math.ceil(Number(listNotification?.pagination?.total) / Number(listNotification?.pagination?.limit))}
                                                handleChangePage={handleChangePage}
                                                manualSorting={true}
                                                sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                                handleChangeSorting={handleChangeSorting}
                                                isLoading={isNotificationLoading}
                                            />
                                        </div>
                                        <ToastContainer closeButton={false} limit={1} />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}</Row>
                </Container>
                <Modal isOpen={!!previewDetail} centered={true} size="xl" scrollable={true} toggle={onClosePreviewDetailClick} keyboard={true}>
                    <ModalHeader toggle={onClosePreviewDetailClick}>
                        {t('Detail')}
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row g-4">
                            <Col md={12}>
                                <div>
                                    <div className="row g-3">
                                        <Col xxl={12} className="mt-0">
                                            <div className="tab-content border border-bottom-0">
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: '140px' }}>{t('WEB_id')}</td>
                                                                <td>
                                                                    {previewDetail?.id}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '140px' }}>{t('WEB_created_at')}</td>
                                                                <td>
                                                                    {previewDetail?.created_at}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '140px' }}>{t('Title')}</td>
                                                                <td>
                                                                    {previewDetail?.title}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '140px' }}>{t('Content')}</td>
                                                                <td>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{ __html: previewDetail?.content }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Col>
                                        <div className="col-lg-12 mb-4">
                                            <div className="hstack gap-2 justify-content-end">
                                                <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewDetailClick}>
                                                    <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                                                    {t('Button Close')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </ModalBody>
                </Modal >
            </div >
        </React.Fragment >
    )
}

export default NotificationPage
