import { AxiosResponse } from "axios";
import APIClient  from "./axiosClient";
import { ICampaignStatistic, ICategoryStatistic, IChartForKeyword, IKeywordStatistic, INewsLog, INewsStatistic, IVisitorStatistic, IWebsiteLog, IWebsiteStatistic, IWebsiteSummariesLog} from "./types/_statistic";
import { PaginationResponse, ResponseData } from "./types/_public";
var api = new APIClient();

const path = '/trackings';
const statisticApi = {
  newsLogs(params: any):Promise<AxiosResponse<ResponseData<INewsLog[]> & PaginationResponse, any>> {
    const url = `${path}/news`
    return api.get(url, params)
  },
  newsStatistics(params: any):Promise<AxiosResponse<ResponseData<INewsStatistic[]> & PaginationResponse, any>> {
    const url = `/medias/news`
    return api.get(url, params)
  },
  websiteSummariesLogs(params: any):Promise<AxiosResponse<ResponseData<IWebsiteSummariesLog[]> & PaginationResponse, any>> {
    const url = `${path}/analytics/summaries`
    return api.get(url, params)
  },
  websiteLogs(params: any):Promise<AxiosResponse<ResponseData<IWebsiteLog[]> & PaginationResponse, any>> {
    const url = `${path}/analytics`
    return api.get(url, params)
  },
  keywordStatistics(params: any):Promise<AxiosResponse<ResponseData<IKeywordStatistic[]> & PaginationResponse, any>> {
    const url = `analytics/keyword-statistics-by-day`
    return api.get(url, params)
  },
  keywordInflows(params: any):Promise<AxiosResponse<ResponseData<IKeywordStatistic[]> & PaginationResponse, any>> {
    const url = `/analytics/keywords-inflow`
    return api.get(url, params)
  },
  dataChartForKeywords(params: any):Promise<AxiosResponse<ResponseData<IChartForKeyword[]>, any>> {
    const url = `${path}/analytics/chart-keywords`
    return api.get(url, params)
  },
  websiteStatistics(params: any):Promise<AxiosResponse<ResponseData<IWebsiteStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/medias`
    return api.get(url, params)
  },
  campaignStatistics(params: any):Promise<AxiosResponse<ResponseData<ICampaignStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/campaigns`
    return api.get(url, params)
  },
  categoryStatistics(params: any):Promise<AxiosResponse<ResponseData<ICategoryStatistic[]> & PaginationResponse, any>> {
    const url = `${path}/categories`
    return api.get(url, params)
  },
  visitorStatistics(params: any):Promise<AxiosResponse<ResponseData<IVisitorStatistic[]> & PaginationResponse, any>> {
    const url = `/medias/visitors`
    return api.get(url, params)
  },
}
export default statisticApi
