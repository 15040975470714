import { createSelector } from '@reduxjs/toolkit'
import { IDashboard } from 'api/types/_dashboard'
import { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboard as onGetDashboard } from 'store/dashboard/thunk';
import { Card, CardBody, Col, Row } from 'reactstrap'

interface listWidget {
    id: number
    label: string
    counter: string
}

interface widgetsProps {
    value: {
        title: string
        data: listWidget[]
    }
}

const Widgets = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<IDashboard>();


    const dispatch: any = useDispatch();
    const selectLayoutState = (state: any) => state.Dashboard;
    const naverDashboardProperties = createSelector(
        selectLayoutState,
        (state) => ({
            dashboard: state.dashboard,
            isDashboardSuccess: state.isDashboardSuccess,
            error: state.error,
        })
    );
    // Inside your component
    const {
        dashboard, error
    } = useSelector(naverDashboardProperties);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(onGetDashboard({}));
                console.log('response', response)
                if (response?.payload?.data) {
                    setData(response?.payload?.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [dispatch]);

    const dataPVDashboard = [
        {
            id: 3,
            cardColor: "secondary",
            label: t('Today'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.pv?.last_updated,
            counter: Number(String(data?.pv?.today).split(',').join('')),
            link: "View all orders",
            icon: "bx bx-search-alt ",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "primary-subtle text-primary"
        },
        {
            id: 2,
            cardColor: "primary",
            label: t('Yesterday'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.pv?.last_updated,
            counter: Number(String(data?.pv?.yesterday).split(',').join('')),
            link: "View all orders",
            icon: "bx bx-search-alt ",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "primary-subtle text-primary"
        },
        {
            id: 4,
            cardColor: "secondary",
            label: t('This month'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.pv?.last_updated,
            counter: Number(String(data?.pv?.month).split(',').join('')),
            link: "View all orders",
            icon: "bx bx-search-alt ",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "primary-subtle text-primary"
        },
    ];

    const dataUVDashboard = [
        {
            id: 13,
            cardColor: "secondary",
            label: t('Today'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.uv?.last_updated,
            counter: Number(String(data?.uv?.today).split(',').join('')),
            link: "View all orders",
            icon: "bx bx-search-alt ",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "success-subtle text-success"
        },
        {
            id: 12,
            cardColor: "primary",
            label: t('Yesterday'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.uv?.last_updated,
            counter: Number(String(data?.uv?.yesterday).split(',').join('')),
            link: "View all orders",
            icon: "bx bx-search-alt ",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "success-subtle text-success"
        },
        {
            id: 14,
            cardColor: "secondary",
            label: t('This month'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.uv?.last_updated,
            counter: Number(String(data?.uv?.month).split(',').join('')),
            link: "View all orders",
            icon: "bx bx-search-alt ",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "success-subtle text-success"
        },
    ];

    return (
        <Row>
            <div className="ms-2">
                <h4 className="mt-2 mb-3 fw-normal">{t('UV')}</h4>
            </div>
            {dataUVDashboard.map((item: any, key: any) => (
                <Col sm={6} md={6} xl={4} key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{item.label}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={item.prefix}
                                            suffix={item.suffix}
                                            separator={item.separator}
                                            end={item.counter}
                                            decimals={item.decimals}
                                            duration={2}
                                        />
                                    </span></h4>
                                    <span className="">{t('Updated')}: {item.percentage}</span>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-" + item.bgcolor}>
                                        <i className={`${item.icon}`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
            <div className="ms-2">
                <h4 className="mt-2 mb-3 fw-normal">{t('PV')}</h4>
            </div>
            {dataPVDashboard.map((item: any, key: any) => (
                <Col sm={6} md={6} xl={4} key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{item.label}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={item.prefix}
                                            suffix={item.suffix}
                                            separator={item.separator}
                                            end={item.counter}
                                            decimals={item.decimals}
                                            duration={2}
                                        />
                                    </span></h4>
                                    <span className="">{t('Updated')}: {item.percentage}</span>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-" + item.bgcolor}>
                                        <i className={`${item.icon}`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}


        </Row>
    )
}

export default Widgets
