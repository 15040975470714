import React, { useEffect } from 'react'
import { Col, Container, Row, Card, CardHeader, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-US'
import ko from 'date-fns/locale/ko'

import TableContainer from '../../Components/Common/TableContainer'
import { listDataSocialPage } from '../../common/data'
import BreadCrumb from 'Components/Common/BreadCrumb'

const TYPE_SELECT_DEFAULT: string = 'socialCategory'
registerLocale('en', en)
registerLocale('ko', ko)

const typeQuery = {
    id: StringParam,
    schedule: StringParam,
}

const SocialPage = () => {
    const { t, i18n } = useTranslation();

    const columns = [
        {
            Header: t('Social Category'),
            accessor: 'socialCategory',
            filterable: true,
            sortable: true,
        },
        {
            Header: t('Social URL'),
            accessor: 'socialURL',
            filterable: false,
            Cell: (Cell: any) => {
                const { text, url } = Cell?.value

                return (
                    <a href={url} rel="noopener">
                        {text}
                    </a>
                )
            },
        },
        {
            Header: t('Article URL'),
            accessor: 'articleURL',
            Cell: (Cell: any) => {
                const { text, url } = Cell?.value

                return (
                    <a href={url} rel="noopener">
                        {text}
                    </a>
                )
            },
            filterable: false,
        },
        {
            Header: t('Views'),
            accessor: 'views',
            filterable: false,
        },
        {
            Header: t('Keyword'),
            accessor: 'keyword',
            filterable: false,
        },
        {
            Header: t('Number of comments'),
            accessor: 'numberOfComments',
            filterable: false,
        },
        {
            Header: t('Date'),
            accessor: 'date',
            filterable: false,
        },
    ]

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
        order_by: withDefault(StringParam, `ASC`),
        name: StringParam,
        ...typeQuery,
    })

    const handleChangePage = (page: any) => {
        setQuery({ page: page + 1 })
    }

    const handleChangeSorting = (sortBy: any) => {
        setQuery((_prev: any) => {
            return { ..._prev, ...sortBy }
        })
    }


    useEffect(() => {
        document.title = `${t('Social')} - ${t('Social')} | NewsHub`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Social')} pageTitle={t('Social')} />
                    <Row>
                        <div className='text-center mt-5'>
                            {t('Under development')}
                        </div>
                        {/* <Col lg={12}>
                            <Card>
                                <CardHeader className="border-0" />
                                <CardBody className="pt-3">
                                    <div className="h-100">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle"
                                            theadClass="table-light"
                                            columns={columns}
                                            data={listDataSocialPage?.list?.length ? listDataSocialPage?.list : []}
                                            customPageSize={query.limit}
                                            customPageCount={Math.ceil(Number(listDataSocialPage?.pagination?.total) / Number(listDataSocialPage?.pagination?.limit))}
                                            customPageIndex={query.page - 1}
                                            totalRecords={listDataSocialPage?.pagination?.total}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                        // isLoading={isReportLoading}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SocialPage
