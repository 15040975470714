import React, { useEffect } from 'react'
import { Col, Container, Row, Card, CardHeader, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-US'
import ko from 'date-fns/locale/ko'

import TableContainer from '../../Components/Common/TableContainer'
import { convertToCommaSeparatedNumber } from '../../helpers/utils'
// import Button from 'Components/Common/'
import BreadCrumb from 'Components/Common/BreadCrumb'

const TYPE_SELECT_DEFAULT: string = 'accountingMonth'
registerLocale('en', en)
registerLocale('ko', ko)

const typeQuery = {
    id: StringParam,
    schedule: StringParam,
}

const SettlementPage = () => {
    const { t, i18n } = useTranslation();

    const showButtonTable = (name: string) => {
        if (name) {
            return (
                <div className="text-center">
                    {/* <Button color="dark" className="max-w-24 w-24" onClick={() => {}}>
                        {t(name)}
                    </Button> */}
                </div>
            )
        } else {
            return null
        }
    }

    const columns = [
        {
            Header: t('Accounting Month'),
            accessor: 'accountingMonth',
            filterable: true,
            sortable: true,
        },
        {
            Header: t('UV'),
            accessor: 'uv',
            filterable: false,
            Cell: (cell: any) => {
                const uv = cell?.value
                return <span>{convertToCommaSeparatedNumber(uv)}</span>
            },
        },
        {
            Header: t('PV'),
            accessor: 'pv',
            Cell: (cell: any) => {
                const pv = cell?.value
                return <span>{convertToCommaSeparatedNumber(pv)}</span>
            },
            filterable: false,
        },
        {
            Header: t('Profit'),
            accessor: 'profit',
            filterable: false,
            Cell: (cell: any) => {
                const profit = cell?.value
                return <span>{`${convertToCommaSeparatedNumber(profit)}${t('Currency Symbol')}`}</span>
            },
        },
        {
            Header: t('Accounting Status'),
            accessor: 'accountingStatus',
            filterable: false,
        },
        {
            Header: <></>,
            accessor: 'button',
            Cell: (cell: any) => {
                const button = cell?.value
                return showButtonTable(button)
            },
            filterable: false,
        },
    ]

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
        order_by: withDefault(StringParam, `ASC`),
        name: StringParam,
        ...typeQuery,
    })

    const handleChangePage = (page: any) => {
        setQuery({ page: page + 1 })
    }

    const handleChangeSorting = (sortBy: any) => {
        setQuery((_prev: any) => {
            return { ..._prev, ...sortBy }
        })
    }

    useEffect(() => {
        document.title = `${t('Settlement')} - ${t('Settlement')} | NewsHub`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Settlement')} pageTitle={t('Settlement')} />
                    <Row>
                        <div className='text-center mt-5'>
                            {t('Under development')}
                        </div>
                        {/* <Col lg={12}>
                            <Card>
                                <CardHeader className="border-0" />
                                <CardBody className="pt-3">
                                    <div className="h-100">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle"
                                            theadClass="table-light"
                                            columns={columns}
                                            data={listDataSettlementPage?.list?.length ? listDataSettlementPage?.list : []}
                                            customPageSize={query.limit}
                                            customPageCount={Math.ceil(Number(listDataSettlementPage?.pagination?.total) / Number(listDataSettlementPage?.pagination?.limit))}
                                            customPageIndex={query.page - 1}
                                            totalRecords={listDataSettlementPage?.pagination?.total}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                        // isLoading={isReportLoading}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SettlementPage
