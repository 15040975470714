import { IMedia } from 'api/types/_media';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { TYPE_NEWS_OPTIONS } from 'helpers/constans';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { getNewsStatistics as onGetNewsStatistics } from "../../../store/thunks";
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'pv';
const NewsStatisticList = () => {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 1500),
    month: withDefault(StringParam, moment(new Date()).format("yyyy-MM")),
    ...typeQuery
  });

  const [dateSearch, setDateSearch] = useState<string>(query?.month || "");

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(TYPE_NEWS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_newshub))[0]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const NewsStatisticsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      newsStatistics: state.newsStatistics,
      isNewsStatisticSuccess: state.isNewsStatisticSuccess,
      isNewsStatisticLoading: state.isNewsStatisticLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { newsStatistics, error, isNewsStatisticLoading } = useSelector(NewsStatisticsProperties);

  useEffect(() => {
    dispatch(onGetNewsStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      // domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      // start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      // end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      month: dateSearch,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      // domain: '',
      // start_date: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD"),
      // end_date: moment(new Date()).format("Y-MM-DD"),
      month: moment(new Date()).format("yyyy-MM"),
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setTypeNewsSearch(null);
    setMediaSearch({ label: t('All Media'), value: '' });
    // setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setDateSearch(moment(new Date()).format("yyyy-MM"));
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Rank'),
        accessor: "rank",
        filterable: true,
        sortable: true,
        thWidth: 100,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('News'),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '150px', maxWidth: '30dvw' }}>
              <Link className='text-normal' to={item?.url} target="_blank">
                {item?.title}
              </Link>
            </div>
          );
        },
      },
      {
        Header: t('PV'),
        accessor: "pv",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        thWidth: 400,
        description: t('Description PV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ maxWidth: '400px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: (t('UV')),
        accessor: "uv",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        thWidth: 400,
        description: t('Description UV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ maxWidth: '400px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: '',
        accessor: "ac",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        thWidth: 50,
        description: '',
        Cell: (cell: any) => (
          <>

          </>
        ),
      },
    ],
    [i18n?.language, listMedia]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([{}]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resTopic?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }))
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }
    if (typeNewsSearch) {
      setTypeNewsSearch((_prev: any) =>
        TYPE_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === typeNewsSearch?.value)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const categories = useMemo(() => {
    return newsStatistics?.map((item: any) => item?.date).reverse() || [];
  }, [newsStatistics, i18n?.language]);

  const series = useMemo(() => {
    const obChart = newsStatistics?.reduce((ob: any, item: any) => {
      ob.pv?.push(item?.pv ? Number((item?.pv).replace(/,/g, '')) : 0);
      ob.uv?.push(item?.uv ? Number((item?.uv).replace(/,/g, '')) : 0);
      return ob;
    }, { pv: [], uv: [], uv_2days_ago: [], uv_8days_ago: [] }) || [];
    return (
      [
        {
          name: t('PV'),
          type: 'column',
          data: obChart?.pv?.reverse(),
        },
        {
          name: t('UV'),
          type: 'column',
          data: obChart?.uv?.reverse(),
        },
      ]
    )
  }, [newsStatistics, i18n?.language]);

  useEffect(() => {
    document.title = `${t('News')} - ${t('Report')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('News')} pageTitle={t('Report')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList" className="ribbon-box right">
                <div className="ribbon round-shape" style={{ background: '#1548a0', fontWeight: 300, fontSize: '0.8rem' }}>{t('This data is analyzed per a day')}</div>
                <CardHeader className="border-0">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                    <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {isNewsStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                  <CountUp
                                    start={0}
                                    end={newsStatistics?.length || 0}
                                    duration={1}
                                  />
                                )}
                              </span></h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <Row className="g-4 align-items-center mt-0">
                        <Col sm={6} md={3} lg={3} className="date-picker-wrapper-custom mt-2">
                          <DatePicker
                            className="form-control search"
                            placeholderText={`${t('Date Car')}...`}
                            value={dateSearch || ''}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                            dateFormat="yyyy-MM"
                            isClearable={true}
                            locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                            onChange={handleChangePicker}
                          />
                        </Col>
                        <Col sm={6} md={9} lg={9} className="hstack gap-1 justify-content-sm-start justify-content-md-start mt-2">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-1"
                              onClick={searchData}
                              disabled={isNewsStatisticLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t('Button Search')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t('Button Reset')}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-4">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={newsStatistics?.length ? newsStatistics : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isNewsStatisticLoading}
                      isShowPagination={false}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewsStatisticList;
