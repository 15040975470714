import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard CRM
import DashboardReducer from "./dashboard/reducer";

// User
import UserReducer from "./user/reducer";

// Statistic
import StatisticReducer from "./statistic/reducer";

//Category
import CategoryReducer from "./category/reducer";

// Role
import RoleReducer from "./role/reducer";

// Notification
import NotificationReducer from "./notification/reducer";

// Setting
import SettingReducer from "./setting/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
  Dashboard: DashboardReducer,
  Statistic: StatisticReducer,
  User: UserReducer,
  Role: RoleReducer,
  Category: CategoryReducer,
  Notification: NotificationReducer,
  Setting: SettingReducer,
});

export default rootReducer;
