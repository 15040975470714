import { Navigate } from 'react-router-dom'

//Dashboard
import Dashboard from '../pages/Dashboard'

//Social
import Social from '../pages/Social'

//Notification
import Notification from '../pages/Notification'

//Settlement
import Settlement from '../pages/Settlement'

//login
import Login from '../pages/Authentication/Login'

import Logout from '../pages/Authentication/Logout'

// User Profile
import Profile from 'pages/User/Profile'

// Setting
import Setting from 'pages/Setting'

import { LIST_ROUTER } from '../helpers/constants'
import NewsLog from 'pages/Statistic/NewsLog'
import NewsStatistic from 'pages/Statistic/NewsStatistic'
import WebsiteLogList from 'pages/Statistic/WebsiteLog'
import SiteStatistic from 'pages/Statistic/SiteStatistic'
import KeywordStatistic from 'pages/Statistic/KeywordStatistic'
import KeywordInflow from 'pages/Statistic/KeywordInflow'
import CampaignStatistic from 'pages/Statistic/CampaignStatistic'
import CategoryStatistic from 'pages/Statistic/CategoryStatistic'
import VisitorStatistic from 'pages/Statistic/VisitorStatistic'


const authProtectedRoutes = [
    { path: `/index`, component: <Dashboard /> },
    { path: `/dashboard`, component: <Dashboard /> },

    { path: `/social`, component: <Social /> },
    { path: `/notification`, component: <Notification /> },
    { path: `/settlement`, component: <Settlement /> },


    { path: '/user/profile', component: <Profile /> },
    { path: '/setting', component: <Setting /> },

    { path: "/report/visitor", component: <VisitorStatistic /> },
    { path: "/report/news", component: <NewsStatistic /> },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: '/',
        exact: true,
        component: <Navigate to="/dashboard" />,
    },
    { path: '*', component: <Navigate to="/dashboard" /> },
]

const publicRoutes = [
    // Authentication Page
    { path: '/logout', component: <Logout /> },
    { path: '/login', component: <Login /> },
]

export { authProtectedRoutes, publicRoutes }
