import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import settingApi from "api/settingApi";

export const getDetail = createAsyncThunk("detail", async () => {
  try {
    const response = await settingApi.detail();
    return response;
  } catch (error) {
    return error;
  }
});

export const updateDetail = createAsyncThunk(
  "update",
  async (params: any = {}) => {
    try {
      const response = await settingApi.update(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);
