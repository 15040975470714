import { IMedia } from 'api/types/_media';
import DatePickerCustom from 'Components/Common/DatePickerCustom';
import SearchFilterMedia from 'Components/Common/SearchFilterMedia';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ARR_INDEX_TEXT_DAY_OF_WEEK, TYPE_NEWS_OPTIONS } from 'helpers/constans';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { getVisitorStatistics as onGetVisitorStatistics } from "../../../store/thunks";
import ChartLine from './ChartLine';
interface Option {
  label: string;
  value: string;
}

const typeQuery = {}

registerLocale('en', en);
registerLocale('ko', ko);

const TYPE_SELECT_DEFAULT: string = 'date';
const VisitorStatisticList = () => {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 1500),
    // domain: withDefault(StringParam, ''),
    // start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")),
    // end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    month: withDefault(StringParam, moment(new Date()).format("yyyy-MM")),
    ...typeQuery
  });

  // const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [dateSearch, setDateSearch] = useState<string>(query?.month || "");

  // const [startDate, endDate] = dateSearch;

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(TYPE_NEWS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_newshub))[0]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Statistic;

  const VisitorStatisticsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      visitorStatistics: state.visitorStatistics,
      isVisitorStatisticSuccess: state.isVisitorStatisticSuccess,
      isVisitorStatisticLoading: state.isVisitorStatisticLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { visitorStatistics, error, isVisitorStatisticLoading } = useSelector(VisitorStatisticsProperties);

  useEffect(() => {
    dispatch(onGetVisitorStatistics(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      // domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      // start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      // end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      month: dateSearch,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      // domain: '',
      // start_date: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD"),
      // end_date: moment(new Date()).format("Y-MM-DD"),
      month: moment(new Date()).format("yyyy-MM"),
      page: 1,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date()
    };
    setQuery(queryNew, "push");
    setTypeNewsSearch(null);
    setMediaSearch({ label: t('All Media'), value: '' });
    // setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setDateSearch(moment(new Date()).format("yyyy-MM"));
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Date'),
        accessor: "date",
        filterable: true,
        sortable: true,
        thWidth: 150,
        thClass: 'text-start',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
          const classText = num === 0 ? 'text-danger row-bg-danger' : (num === 6 ? 'text-primary row-bg-primary' : '');
          const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
          return (
            <div className="text-start" style={{ maxWidth: '200px' }}>
              <span className={classText}>{moment(arrDate?.[0] || '', 'Y-MM-DD').format("MM-DD")} ({textNote})</span>
            </div>
          )
        },
      },
      {
        Header: t('PV'),
        accessor: "pv",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        thWidth: 400,
        description: t('Description PV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ maxWidth: '400px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: (t('UV')),
        accessor: "uv",
        filterable: false,
        sortable: true,
        thClass: 'text-end',
        thWidth: 400,
        description: t('Description UV'),
        Cell: (cell: any) => (
          <>
            <div className="text-end me-3" style={{ maxWidth: '400px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: '',
        accessor: "fix-layout",
        filterable: false,
        sortable: false,
        thWidth: 600,
        Cell: (cell: any) => { },
      },
      // {
      //   Header: t('Referrer Rate'),
      //   thClass: 'text-center',
      //   filterable: false,
      //   sortable: true,
      //   description: t('Referrer Rate'),
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <div className="text-center me-3">
      //         {t('Visitor Naver')} {item.naver_rate}%, {t('Visitor Google')} {item.google_rate}%, {t('Visitor Etc')} {item.etc_rate}%
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: t('UV (Yesterday)'),
      //   accessor: "uv_yesterday",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   description: t('Description UV (Yesterday)'),
      //   Cell: (cell: any) => (
      //     <>
      //       <div className="text-end me-3">{cell?.value}</div>
      //     </>
      //   ),
      // },
      // {
      //   Header: t('UV (2 Days ago)'),
      //   accessor: "uv_2days_ago",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   description: t('Description UV of news which were published between 2 and 7 days ago'),
      //   Cell: (cell: any) => (
      //     <>
      //       <div className="text-end me-3">{cell?.value}</div>
      //     </>
      //   ),
      // },
      // {
      //   Header: t('UV (8 Days ago)'),
      //   accessor: "uv_8days_ago",
      //   filterable: false,
      //   sortable: true,
      //   thClass: 'text-end',
      //   description: t('Description UV of news which were published 8 days before'),
      //   Cell: (cell: any) => (
      //     <>
      //       <div className="text-end me-3">{cell?.value}</div>
      //     </>
      //   ),
      // },
    ],
    [i18n?.language, listMedia]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([{}]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resTopic?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }))
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }
    if (typeNewsSearch) {
      setTypeNewsSearch((_prev: any) =>
        TYPE_NEWS_OPTIONS_LANG?.filter((e: any) => e.value === typeNewsSearch?.value)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const categories = useMemo(() => {
    return visitorStatistics?.map((item: any) => item?.date).reverse() || [];
  }, [visitorStatistics, i18n?.language]);

  const series = useMemo(() => {
    const obChart = visitorStatistics?.reduce((ob: any, item: any) => {
      ob.pv?.push(item?.pv ? Number((item?.pv).replace(/,/g, '')) : 0);
      ob.uv?.push(item?.uv ? Number((item?.uv).replace(/,/g, '')) : 0);
      return ob;
    }, { pv: [], uv: [], uv_2days_ago: [], uv_8days_ago: [] }) || [];
    return (
      [
        {
          name: t('PV'),
          type: 'column',
          data: obChart?.pv?.reverse(),
        },
        {
          name: t('UV'),
          type: 'column',
          data: obChart?.uv?.reverse(),
        },
      ]
    )
  }, [visitorStatistics, i18n?.language]);

  useEffect(() => {
    document.title = `${t('Visitor')} - ${t('Report')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Visitor')} pageTitle={t('Report')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList" className="ribbon-box right">
                <div className="ribbon round-shape" style={{ background: '#1548a0', fontWeight: 300, fontSize: '0.8rem' }}>{t('This data is analyzed per 10 mins')}</div>
                <CardHeader className="border-0">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
                    <Card className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0" style={{ width: '200px' }}>
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                {isVisitorStatisticLoading ? <Spinner size="sm" ></Spinner> : (
                                  <CountUp
                                    start={0}
                                    end={visitorStatistics?.length || 0}
                                    duration={1}
                                  />
                                )}
                              </span></h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <Row className="g-4 align-items-center mt-0">
                        <Col sm={6} md={3} lg={3} className="date-picker-wrapper-custom mt-2">
                          <DatePicker
                            className="form-control search"
                            placeholderText={`${t('Date Car')}...`}
                            value={dateSearch || ''}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                            dateFormat="yyyy-MM"
                            isClearable={true}
                            locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                            onChange={handleChangePicker}
                          />
                        </Col>
                        <Col sm={6} md={9} lg={9} className="hstack gap-1 justify-content-sm-start justify-content-md-start mt-2">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-1"
                              onClick={searchData}
                              disabled={isVisitorStatisticLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t('Button Search')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t('Button Reset')}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <ChartLine
                    className="mb-5"
                    title={t('Visit')}
                    categories={categories}
                    series={series}
                  />
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={visitorStatistics?.length ? visitorStatistics : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isVisitorStatisticLoading}
                      isShowPagination={false}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VisitorStatisticList;
