import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "Components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const { userPermissions } = useRole();

    const [menuActive, setMenuActive] = useState<string>('DASHBOARD');

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
    }, [history]);

    const menuItems: any = [
        {
            id: "dashboard",
            label: t('Dashboards'),
            icon: <FeatherIcon icon="home" className="icon-dual" />,
            link: "/dashboard",
            click: function (e: any) {
                setMenuActive((prev) => 'DASHBOARD');
                setIsCurrentState((prev) => 'Dashboard');
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'DASHBOARD'),
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
            // subItems: []
        },
        {
            id: 'report',
            label: t('Report'),
            icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'REPORT');
                setIsCurrentState((prev) => "Report");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'REPORT'),
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
            subItems: [
                {
                    id: "report-visitor",
                    label: t('Visitor'),
                    link: "/report/visitor",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_LOG_LIST, userPermissions),
                },
                {
                    id: "report-news",
                    label: t('News'),
                    link: "/report/news",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_LOG_LIST, userPermissions),
                },
            ]
        },
        {
            id: 'notification',
            label: t('Notification'),
            icon: <FeatherIcon icon="bell" className="icon-dual" />,
            link: '/notification',
            click: function (e: any) {
                // e.preventDefault();
                setMenuActive((prev) => 'NOTIFICATION');
                setIsCurrentState((prev) => "Notification");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'NOTIFICATION'),
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
        },
        {
            id: 'setting',
            label: t('Setting'),
            icon: <FeatherIcon icon="settings" className="icon-dual" />,
            link: '/setting',
            click: function (e: any) {
                // e.preventDefault();
                setMenuActive((prev) => 'SETTING');
                setIsCurrentState((prev) => "Setting");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'SETTING'),
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
