import { AxiosResponse } from "axios";
import APIClient from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
var api = new APIClient();

const path = "/partners";
const settingApi = {
  detail(): Promise<
    AxiosResponse<ResponseData<any[]> & PaginationResponse, any>
  > {
    const url = `${path}/setting`;
    return api.get(url);
  },

  update(dataForm: any) : Promise<AxiosResponse<ResponseData<any>, any>>  {
    const url = `${path}/setting`;
    return api.put(url, dataForm)
  },
};
export default settingApi;
