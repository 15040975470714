import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Widget from './Widgets'

const DashboardEcommerce = () => {
    const { t } = useTranslation()
    document.title = `${t('Dashboard')} | NewsHub`

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <Widget />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DashboardEcommerce
