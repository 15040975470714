import { createSlice } from "@reduxjs/toolkit";
import { getDetail, updateDetail } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";

export interface IState {
  detail: (ResponseData<any[]> & PaginationResponse) | null;
  isSettingLoading: boolean;
  isSettingSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  detail: null,
  isSettingLoading: false,
  isSettingSuccess: false,

  error: {},
};

const SettingSlice = createSlice({
  name: "Setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get setting
    builder.addCase(getDetail.pending, (state: IState, action: any) => {
      state.isSettingLoading = true;
    });
    builder.addCase(getDetail.fulfilled, (state: IState, action: any) => {
      state.detail = action.payload.data;
      state.isSettingSuccess = true;
      state.isSettingLoading = false;
    });
    builder.addCase(getDetail.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSettingSuccess = false;
      state.isSettingLoading = false;
    });

    //get update setting
    builder.addCase(updateDetail.pending, (state: IState, action: any) => {
      state.isSettingLoading = true;
    });
    builder.addCase(updateDetail.fulfilled, (state: IState, action: any) => {
      state.detail = action.payload.data;
      state.isSettingSuccess = true;
      state.isSettingLoading = false;
    });
    builder.addCase(updateDetail.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSettingSuccess = false;
      state.isSettingLoading = false;
    });
  },
});

export default SettingSlice.reducer;
